import React, { useEffect } from "react";

const VaalituloksetAv = () => {
  const baseUrl = (() => {
    if (typeof window !== "undefined") {
      const url = window.location.href;
      if (url.includes("localhost:3000")) {
        return "http://localhost:3000";
      }
      if (url.includes("imediat-web.fiare.qtstage.io")) {
        return "https://imediat-web.fiare.qtstage.io";
      }
      if (url.includes("yhteissivusto.fiare.qtstage.io")) {
        return "https://yhteissivusto.fiare.qtstage.io";
      }
      if (url.includes("ilkkapohjalainen.fi")) {
        return "https://ilkkapohjalainen.fi";
      }
    }
    return "https://ilkkapohjalainen.fi"; // Default fallback
  })();

  // link to other election to show in embed
  const otherElectionUrl = `${baseUrl}/vaalitulokset`;

  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://tulospalvelu.vaalit.almamedia.fi/av-2025/tulospalvelu.js";
    tag.async = true;
    document.body.appendChild(tag);
    window.disableAjaxNavigation = false;

    return () => {
      document.body.removeChild(tag);
      window.disableAjaxNavigation = true;
    };
  }, []);

  return (
    <>
      <div id="alma-tulospalvelu-app" data-other-election-url={otherElectionUrl} />
    </>
  );
};

export { VaalituloksetAv };
